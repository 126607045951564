import moment from 'moment'

export const projectName= "Live Marvellous at Balewadi"
export const serverErrorMessage= "Something Went Wrong!"

export const custTime= "9 AM"

export const selectableTimes= ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"]

export const scheduleConfUrl = 'public_api/leads'
export const bookingUrl = 'public/bookings/new'
export const userTypeUrl = 'public/user_type'
export const enquiryFormUrl = "public/customer"

export const paymentPlans = ['CLP', 'EMI Scheme', 'EMI+CLP', 'Subvention 1:99', 'Subvention 10:90']

export function confCallParams(callParams){
  let toSend = {}
  toSend['name'] = callParams.custName
  toSend['email'] = callParams.custEmail
  toSend['phone'] = callParams.custPhone
  toSend['date'] = callParams.custDate ? moment(callParams.custDate).format("DD-MM-YYYY") : null
  toSend['time'] = callParams.custTime
  toSend['project'] = projectName
  return toSend
}

export function santizeBookingParams(bParams){
  let toSend = {}
  if(bParams.leadNo){
    toSend['ln'] = bParams.leadNo
  }else{
    toSend['p'] = projectName
  }
  toSend['t'] = bParams.typologyName
  toSend['f'] = bParams.floorName
  toSend['in'] = bParams.flatName
  toSend['wi'] = bParams.wingName
  toSend['pn'] = bParams.selectedPlan
  toSend['cpuu'] = bParams.cpId
  return toSend
}

export const masterJson = 
{
  "Buildings" : [
    // {
    //   "uuid": "1",
    //   "key": "Plot 1",
    //   "value": "Plot 1",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M110.5 464.5L143.5 446.5L153.5 466L120.5 481.5L110.5 464.5Z"
    // },
    // {
    //   "uuid": "2",
    //   "key": "Plot 2",
    //   "value": "Plot 2",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M101.5 448L135 430.5L143.5 446.5L110.5 464.5L101.5 448Z"
    // },
    // {
    //   "uuid": "3",
    //   "key": "Plot 3",
    //   "value": "Plot 3",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M88.5 423L122.5 405.5L135 430.5L101.5 448L88.5 423Z"
    // },
    // {
    //   "uuid": "4",
    //   "key": "Plot 4",
    //   "value": "Plot 4",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M64 376.5L98 358.5L122.5 405.5L88.5 423L64 376.5Z"
    // },
    // {
    //   "uuid": "5",
    //   "key": "Plot 6",
    //   "value": "Plot 6",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M98 358.5L129 341.5L142 366L110.5 382.5L98 358.5Z"
    // },
    // {
    //   "uuid": "6",
    //   "key": "Plot 7",
    //   "value": "Plot 7",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M110.5 382.5L142 366L151.5 384L120 400.5L110.5 382.5Z"
    // },
    // {
    //   "uuid": "7",
    //   "key": "Plot 8",
    //   "value": "Plot 8",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M120 400.5L151.5 384L161 401L129 418.5L120 400.5Z"
    // },
    // {
    //   "uuid": "8",
    //   "key": "Plot 9",
    //   "value": "Plot 9",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M129 418.5L161 401L170.5 419L138 436L129 418.5Z"
    // },
    // {
    //   "uuid": "9",
    //   "key": "Plot 10",
    //   "value": "Plot 10",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M138 436L170.5 419L178.5 436.5L156.5 471L138 436Z"
    // },
    // {
    //   "uuid": "10",
    //   "key": "Plot 11",
    //   "value": "Plot 11",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M158 355.5L181 343.5L189.5 345.5L196.5 345L180 315L145 332.5L158 355.5Z"
    // },
    // {
    //   "uuid": "11",
    //   "key": "Plot 12",
    //   "value": "Plot 12",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M158 355.5L181 343.5L189.5 345.5L191.5 373L192.5 392L185 409L158 355.5Z"
    // },
    // {
    //   "uuid": "12",
    //   "key": "Plot 13",
    //   "value": "Plot 13",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M219.5 342.5L199.5 305L180 315L196.5 345L219.5 342.5Z"
    // },
    // {
    //   "uuid": "13",
    //   "key": "Plot 14",
    //   "value": "Plot 14",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M219.5 342.5L199.5 305L216.5 295L244 347L219.5 342.5Z"
    // },
    // {
    //   "uuid": "14",
    //   "key": "Plot 15",
    //   "value": "Plot 15",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M251.5 316.5L234 285L216.5 295L233 326L251.5 316.5Z"
    // },
    // {
    //   "uuid": "15",
    //   "key": "Plot 16",
    //   "value": "Plot 16",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M251.5 316.5L271 353L244 347L233 326L251.5 316.5Z"
    // },
    // {
    //   "uuid": "16",
    //   "key": "Plot 17",
    //   "value": "Plot 17",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M294 357.5L284.5 341.5L331.5 317L341.5 331.5L355 363L294 357.5Z"
    // },
    // {
    //   "uuid": "18",
    //   "key": "Plot 18",
    //   "value": "Plot 18",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M329.5 317.5L318 295L272.5 319L284.5 341.5L329.5 317.5Z"
    // },
    // {
    //   "uuid": "18",
    //   "key": "Plot 19",
    //   "value": "Plot 19",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M306.5 272.5L318 295L272.5 319L260.5 297L306.5 272.5Z"
    // },
    // {
    //   "uuid": "19",
    //   "key": "Plot 20",
    //   "value": "Plot 20",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M306.5 272.5L281 259.5L249 278L260.5 297L306.5 272.5Z"
    // },
    // {
    //   "uuid": "20",
    //   "key": "Plot 60",
    //   "value": "Plot 60",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M300 244.5L339.5 229L349.5 256.5L321.5 267L300 244.5Z"
    // },
    // {
    //   "uuid": "21",
    //   "key": "Plot 61",
    //   "value": "Plot 61",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M339.5 229L369.5 219L379 246L349.5 256.5L339.5 229Z"
    // },
    // {
    //   "uuid": "22",
    //   "key": "Plot 62",
    //   "value": "Plot 62",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M321.5 267L379 246L386 267L338.5 284.5L321.5 267Z"
    // },
    // {
    //   "uuid": "23",
    //   "key": "Plot 63",
    //   "value": "Plot 63",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M338.5 284.5L386 267L392 283.5L350 298.5L338.5 284.5Z"
    // },
    // {
    //   "uuid": "24",
    //   "key": "Plot 64",
    //   "value": "Plot 64",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M350 298.5L392 283.5L400.5 306L357.5 321.5L350 298.5Z"
    // },
    // {
    //   "uuid": "25",
    //   "key": "Plot 65",
    //   "value": "Plot 65",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M357.5 321.5L400.5 306L404 314.5H407.5L412.5 346L369.5 356L357.5 321.5Z"
    // },
    // {
    //   "uuid": "26",
    //   "key": "Plot 66",
    //   "value": "Plot 66",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M407.5 314.5L444 313L447.5 339L412.5 346L407.5 314.5Z"
    // },
    // {
    //   "uuid": "27",
    //   "key": "Plot 67",
    //   "value": "Plot 67",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M444 313L481.5 311L483.5 332.5L447.5 339L444 313Z"
    // },
    // {
    //   "uuid": "28",
    //   "key": "Plot 68",
    //   "value": "Plot 68",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M453 244L475.5 236.5L479 247L480.5 284.5L467 285.5L453 244Z"
    // },
    // {
    //   "uuid": "29",
    //   "key": "Plot 69",
    //   "value": "Plot 69",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M453 244L431.5 252L444 289L467 285.5L453 244Z"
    // },
    // {
    //   "uuid": "30",
    //   "key": "Plot 70",
    //   "value": "Plot 70",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M409.5 259.5L431.5 252L444 289L419.5 292L409.5 259.5Z"
    // },
    // {
    //   "uuid": "31",
    //   "key": "Plot 71",
    //   "value": "Plot 71",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M409.5 259.5L453 244L445.5 220.5L400 236L409.5 259.5Z"
    // },
    // {
    //   "uuid": "32",
    //   "key": "Plot 72",
    //   "value": "Plot 72",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M493.5 230L453 244L445.5 220.5L487 207L493.5 230Z"
    // },
    // {
    //   "uuid": "33",
    //   "key": "Plot 73",
    //   "value": "Plot 73",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M392 211L436.5 195.5L445.5 220.5L400 236L392 211Z"
    // },
    // {
    //   "uuid": "34",
    //   "key": "Plot 74",
    //   "value": "Plot 74",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M457 188L436.5 196L445.5 220.5L466 213.5L457 188Z"
    // },
    // {
    //   "uuid": "35",
    //   "key": "Plot 75",
    //   "value": "Plot 75",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M457 188L477 180.5L486.5 207L466 213.5L457 188Z"
    // },
    // {
    //   "uuid": "36",
    //   "key": "Plot 76",
    //   "value": "Plot 76",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M494 177L529 165.5L537.5 189.5L502 202L494 177Z"
    // },
    // {
    //   "uuid": "37",
    //   "key": "Plot 77",
    //   "value": "Plot 77",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M561.5 154L529 165.5L537.5 189.5L569.5 179L561.5 154Z"
    // },
    // {
    //   "uuid": "38",
    //   "key": "Plot 78",
    //   "value": "Plot 78",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M578 205L546 215.5L537.5 189.5L569.5 179L578 205Z"
    // },
    // {
    //   "uuid": "39",
    //   "key": "Plot 79",
    //   "value": "Plot 79",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M511 227L546 215.5L537.5 189.5L502 202L511 227Z"
    // },
    // {
    //   "uuid": "40",
    //   "key": "Plot 89",
    //   "value": "Plot 89",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M723.5 140.5L672 148L675.5 172.5L718.5 167.5L723.5 140.5Z"
    // },
    // {
    //   "uuid": "41",
    //   "key": "Plot 90",
    //   "value": "Plot 90",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M705 143L672 148L667 114.5L700 110.5L705 143Z"
    // },
    // {
    //   "uuid": "42",
    //   "key": "Plot 91",
    //   "value": "Plot 91",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M705 143L723.5 140.5L731 106L700 110.5L705 143Z"
    // },
    // {
    //   "uuid": "43",
    //   "key": "Plot 92",
    //   "value": "Plot 92",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M701 85.5L737 80.5L731 106L704.5 110L701 85.5Z"
    // },
    // {
    //   "uuid": "44",
    //   "key": "Plot 93",
    //   "value": "Plot 93",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M701 85.5L664 90L667 114.5L704.5 110L701 85.5Z"
    // },
    // {
    //   "uuid": "45",
    //   "key": "Plot 94",
    //   "value": "Plot 94",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M693.5 86.5L664 90L661.5 62.5L690.5 58.5L693.5 86.5Z"
    // },
    // {
    //   "uuid": "46",
    //   "key": "Plot 95",
    //   "value": "Plot 95",
    //   "status": "Available",
    //   "opacity": "0.01",
    //   "fill": "#5B5BBA",
    //   "area": "1,800",
    //   "path": "M693.5 86.5L737 80.5L742 52.5L690.5 58.5L693.5 86.5Z"
    // },
    {
      "key": "Entrance Gate",
      "value": "Entrance Gate",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Entrance-Gate.jpg",
      "path": "M94.5 154.5V47H140.5V154.5H94.5Z"
    },
    {
      "key": "Entrance Gate b",
      "value": "Entrance Gate",
      "status": "unavailable",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Entrance-Gate.jpg",
      "path": "M95 390V310H141V390H95Z"
    },
    {
      "key": "Entrance Lobby",
      "value": "Entrance Lobby",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Entrance-Lobby.jpg",
      "path": "M231 247.5L235.5 159.5L320 167.5L316 226H341V253.5L231 247.5Z"
    },
    {
      "key": "Cafe",
      "value": "Cafe",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Residential-Cafe.jpg",
      "path": "M316.5 224L320 167.5L420 171.5L415 241.5L341 235.5V226L316.5 224Z"
    },
    {
      "key": "Changing Room",
      "value": "Changing Room",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "path": "M435.5 186.5L439.5 172.5H469.5L465 263H431.5L435.5 217.5V186.5Z"
    },
    {
      "key": "Swimming Pool",
      "value": "Swimming Pool",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Swimming-Pool.jpg",
      "path": "M464 293.5L470 172.5L551 178.5L544 298.5L464 293.5Z"
    },
    {
      "key": "Children's Play Area",
      "value": "Children's Play Area",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "path": "M379 536V418.5L446 421.5V536H379Z"
    },
    {
      "key": "Multi Purpose Court",
      "value": "Multi Purpose Court",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/MultiPurpose-Court.jpg",
      "path": "M379 660.5V554H459.5V660.5H379Z"
    },
    {
      "key": "Deck With Sitting Area",
      "value": "Deck With Sitting Area",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Sitting-Area.jpg",
      "path": "M307 671.5V607L363 560.5V668L307 671.5Z"
    },
    {
      "key": "Indoor Game",
      "value": "Indoor Game",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Indoor-game.jpg",
      "path": ""
    },
    {
      "key": "Residential Gym",
      "value": "Residential Gym",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Residential-Gym.jpg",
      "path": ""
    },
    {
      "key": "Residential Library",
      "value": "Residential Library",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Residential-library.jpg",
      "path": ""
    },
    {
      "key": "Residential Lobby",
      "value": "Residential Lobby",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Residential-Lobby.jpg",
      "path": ""
    },
    {
      "key": "Gaming Lounge",
      "value": "Gaming Lounge",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#5B5BBA",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/Gaming-lounge.jpg",
      "path": ""
    }

  ],
  "Variants" : [
    {
      "uuid": "1",
      "key": "villa2",
      "value": "2 BHK ",
      "price": "1.30 Cr",
      "area": "890",
      "carpetArea": "890",
      "configuration": "2 BHK",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-2-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/2bhk-type1.png",
      "paths":[{
        "path":"M577.08 205.118H532.58V216.618H439.08V179.118H371.58V152.618H289.58V36.1179H371.58V2.11792H532.58V36.1179H618.08V143.618H577.08V205.118Z",
        "fill":"#5B5BBA",
        "opacity":"0.01",
        "fillOpacity":"0.3"
      },{
        "path":"M660.58 205.618H705.08V217.118H798.58V179.618H866.08V153.118H948.08V36.6179H866.08V2.61792H705.08V36.6179H619.58V144.118H660.58V205.618Z",
        "fill":"#5B5BBA",
        "opacity":"0.01",
        "fillOpacity":"0.3",
      }]
    },
    {
      "uuid": "2",
      "key": "villa3",
      "value": "3 BHK Smart",
      "price": "1.60 Cr",
      "area": "1100",
      "carpetArea": "1100 ",
      "configuration": "3 BHK Smart",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-3-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/3bhk-type1.png",
      "paths":[{
        "path":"M755.58 403.118V498.618H668.08V512.618H581.58V498.618H404.08V370.618H430.58V330.618H599.58V267.618H755.58V330.618H705.08V367.118H737.58V403.118H755.58Z",
        "fill":"#5B5BBA",
        "opacity":"0.01",
        "fillOpacity":"0.3"
      }]
    },
    {
      "uuid": "3",
      "key": "villa4",
      "value": "3 BHK Spacious",
      "price": "1.90 Cr",
      "area": "1,400",
      "carpetArea": "1,400",
      "configuration": "3 BHK Spacious",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-4-elevation.jpg",
      "floorPlan": " https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/3bhk-type2.png",
      "paths":[{
        "path":"M916.58 217.118V264.118H950.58V382.118H1017.58V353.618H1176.08V260.118H1219.58V74.6179H1176.08V36.6179H1110.08H1065.58H1036.08H950.58V144.118V217.118H916.58Z",
        "fill":"#5B5BBA",
        "opacity":"0.01",
        "fillOpacity":"0.3"
      }]
     
    },
    {
      "uuid": "4",
      "key": "villa5",
      "value": "4 BHK ",
      "price": "2.60 Cr",
      "area": "1,704",
      "carpetArea": "1,704",
      "configuration": "4 BHK",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-4-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/4bhk-type1.png",
      "paths":[{
        "path":"M52.5801 37.6179H289.58V461.618H66.5801V396.618H107.58V344.618H60.5801V260.618H17.5801V78.1179H52.5801V37.6179Z",
        "fill":"#5B5BBA",
        "opacity":"0.01",
        "fillOpacity":"0.3"
      }]
    },
    // {
    //   "uuid": "5",
    //   "key": "villa6",
    //   "value": "4 BHK",
    //   "price": "99L",
    //   "area": "1,800",
    //   "carpetArea": "1,500",
    //   "configuration": "5 Bed Villa",
    //   "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-4-elevation.jpg",
    //   "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/4bhk-type1.png"
    // }
  ],
}

export const FormSteps = ["Explore Project","Explore Master layout","Select Variant","Check Details","KYC & Make Payment"]